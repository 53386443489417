import Service from "../../service/index";
// import BaseService from "../../service/service";

const Courses = {
  name: "Courses",
  stateFactory: true,
  namespaced: true,
  state: {
    course: [],
    courseEnroll: [],
    allCourses: [],
    allCoursesNostatus: [],
    menus: [],
  },
  mutations: {
    SET_COURSE: (state, data) => {
      state.course = data;
    },
    SET_ALLCOURSE: (state, data) => {
      state.allCourses = data;
    },
    SET_ALLCOURSE_NOSTATUS: (state, data) => {
      state.allCoursesNostatus = data;
    },
    CLEAR_COURSE: (state, data) => {
      state.course = data;
    },
    STATUS_CHECK: (state, data) => {
      state.courseEnroll = data;
    },
    MENUS_LIST: (state, data) => {
      state.menus = data;
    },
  },
  actions: {
    setCourse({ commit }, data) {
      commit("SET_COURSE", data);
      return data;
    },
    clearCourse({ commit }, data) {
      data = [];
      commit("CLEAR_COURSE", data);
      return data;
    },
    
    getCourse({ commit }, model) {
      commit("SET_COURSE", []);
      return Service.Courses.onGetCourse(model.id, "", model.user_id).then(
        (response) => {
          if (response.code === 200) {
            let response_data = response.result.courses.data[0];
            commit("SET_COURSE", response_data);
            return response;
          }
        }
      );
    },
    // eslint-disable-next-line no-unused-vars
    getCourseAnswer({ commit }, { id, accessToken, enrollId }) {
      return Service.Courses.onGetCourseAnswer(id, accessToken, enrollId).then(
        (response) => {
          if (response.code === 200) {
            return response;
          }
        }
      );
    },
    // eslint-disable-next-line no-unused-vars
    getIndexCourses({ commit },id) {
      return Service.Courses.onGetIndexCourses(id).then((response) => {
        if (response.code === 200) {
          let result = response.result;
          return result.courses;
        }
      });
    },
    // eslint-disable-next-line no-unused-vars
    getAllCoursesData({ commit },id) {
      return Service.Courses.onGetAllCoursesData(id).then((response) => {
        if (response.code === 200) {
          let result = response.result;
          return result.courses;
        } 
      });
    },
    // eslint-disable-next-line no-unused-vars
    getAllCoursesByCategoryData({ commit }, { categoryId, userId }) {
      return Service.Courses.getAllCoursesByCategoryData(categoryId,userId).then(
        (response) => {
          if (response.code === 200) {
            let result = response.result;
            return result;
          }
        }
      );
    },

    getAllCourse(
      { commit },
      query = { category: null, keyword: null, order: null }
    ) {
      return Service.Courses.onGetAllCourses(
        query.category,
        query.keyword,
        query.order
      ).then((response) => {
        if (response.code === 200) {
          let result = response.result;
          commit("SET_ALLCOURSE", result.courses.data);
          return response;
        }
      });
    },
    getUserCourse({ commit }, { model, accessToken }) {
      return Service.Courses.onGetUserCourse(model, accessToken).then(
        (response) => {
          if (response.code === 200) {
            commit("SET_ALLCOURSE", response.result);
            // let response_data = response.result.courses.data[0];
            // commit("SET_ALLCOURSE", response_data);
            return response;
          }
        }
      );
    },
    getUserCourseNostatus({ commit }, { model, accessToken }) {
      return Service.Courses.onGetUserCourse(model, accessToken).then(
        (response) => {
          if (response.code === 200) {
            commit("SET_ALLCOURSE_NOSTATUS", response.result);
            // let response_data = response.result.courses.data[0];
            // commit("SET_ALLCOURSE", response_data);
            return response;
          }
        }
      );
    },
    registerCourse({ commit }, { data, accessToken }) {
      return Service.Courses.onRegisterCourse(data, accessToken).then(
        (response) => {
          if (response.code === 200) {
            if (response.result.length > 0)
              commit("SET_COURSE", response.result);
            return response;
          }
        }
      );
    },
    checkCourseStatus({ commit }, { model, accessToken }) {
      return Service.Courses.onCheckCourseStatus(model, accessToken).then(
        (response) => {
          if (response.code === 200) {
            commit("STATUS_CHECK", response.result);
            return response.result;
          }
        }
      );
    },
    // eslint-disable-next-line no-unused-vars
    onGetBanners({ commit }) {
      return Service.Courses.onGetBanners().then((response) => {
        if (response.code === 200) {
          return response.result;
        }
      });
    },
    // eslint-disable-next-line no-unused-vars
    onGetCertificate({ commit }, { userId, subjectId }) {
      return Service.Courses.onGetCertificate(userId, subjectId).then(
        (response) => {
          if (response.code === 200) {
            return response.result;
          }
        }
      );
    },
    // eslint-disable-next-line no-unused-vars
    onGetLessonProgess({ commit }, { subjectId, enrollId, accessToken }) {
      return Service.Courses.onGetLessonProgess(
        subjectId,
        enrollId,
        accessToken
      ).then((response) => {
        if (response.code === 200) {
          return response.result;
        }
      });
    },
    // eslint-disable-next-line no-unused-vars
    onGetMenus({ commit }) {
      return Service.Courses.onGetMenus().then((response) => {
        if (response.code === 200) {
          commit("MENUS_LIST", response.result);
          return response.result;
        }
      });
    },
    getcCurseManage_Rights({ commit }, { userId, subjectId }) {
      return Service.Courses.getcCurseManage_Rights(userId, subjectId).then(
        (response) => {
          if (response.code === 200) {
            commit("Manage_Rights", response.result);
            return response.result;
          }
        }
      );
    },
    // eslint-disable-next-line no-unused-vars
    sendPaymentSlip({ commit }, { data, accessToken }) {
        return Service.Courses.onSendPaymentSlip(data, accessToken).then(
        (response) => {
          if (response.status == "success") {
            return response;
          }
        }
      );
    },
  },
};

export default Courses;
