import Service from "../../service/index";

const Lessons = {
  name: "Courses",
  stateFactory: true,
  namespaced: true,
  state: {
    lessons: [],
    lessonProgress: [],
  },
  mutations: {
    SET_LESSONS: (state, data) => {
      state.lessons = data;
    },
    SET_LESSONS_PROGRESS: (state, data) => {
      state.lessonProgress = data;
    },
  },
  actions: {
    getLessons({ commit }, id) {
      return Service.Lessons.onGetLessons(id).then((response) => {
        if (response.code === 200) {
          let response_data = response.result;
          commit("SET_LESSONS", response_data);
          return response;
        }
      });
    },
    saveLessonProgress({ commit }, { model, accessToken }) {
      return Service.Lessons.onSaveLessonProgress(model, accessToken).then(
        (response) => {
          if (response.code === 200) {
            let response_data = response.result;
            commit("SET_LESSONS_PROGRESS", response_data);
            return response;
          }
        }
      );
    },
  },
};

export default Lessons;
