// import Service from "../service";
import AuthService from "../authService"

const QA = {
    getQA(subject_id) {
        return AuthService.get("/qa/getAll?subject_id="+subject_id);
    },
    saveQA(data) {
        return AuthService.post("/qa/save",data);
    },
    
}
export default QA;