import Service from "../service";
import AuthService from "../authService"

const Group = {
    onGetGroup() {
        return Service.get("/getBookGroup");
    },
    getGroupByID(payload) {
        return AuthService.get("/getGroupByID?groupid=" + payload.groupid + "&page=" + payload.page + "&filterid=" + payload.filterid);

    },
    saveFavoriteBook(bookid) {
        return AuthService.post("/saveFavoriteBook", {
            bookid: bookid
        });
    },
    deleteFavoriteBook(bookid) {
        return AuthService.post("/deleteFavoriteBook", {
            bookid: bookid
        });
    },
    // getFavoriteBook(payload) {
    //     return AuthService.get("/getFavoriteBook?page=" + payload.page + "&groupid=" + payload.groupid);
    // },
    filterBook(groupid) {
        return AuthService.get('/filterBook?groupid=' + groupid);
    },
    getBookBanner() {
        return Service.get('/bookbanner');
    },
    getFlipbook(bookid) {
        return AuthService.get('/getFlipbook?bookid=' + bookid);
    }

};

export default Group;