import Service from "../../service/index";

const Teachers = {
  name: "Teachers",
  stateFactory: true,
  namespaced: true,
  state: {
    teachersList: [],
    teacher: [],
  },
  mutations: {
    SET_TEACHER_LIST: (state, data) => {
      state.teachersList = data;
    },
    SET_TEACHER: (state, data) => {
      state.teacher = data;
    },
  },
  actions: {
    TeachersList({ commit }, data = { search: null }) {
      return Service.Teachers.onTeacherList(data.search).then((response) => {
        if (response.code === 200) {
          let response_data = response.result;
          commit("SET_TEACHER_LIST", response_data);
          return response;
        }
      });
    },
    getTeacher({ commit }, id) {
      return Service.Teachers.onGetTeacher(id).then((response) => {
        if (response.code === 200) {
          let response_data = response.result;
          commit("SET_TEACHER", response_data);
          return response;
        }
      });
    },
    // eslint-disable-next-line no-unused-vars
    getIndexTeachers({ commit }) {
      return Service.Teachers.onGetIndexTeachers().then((response) => {
        if (response.code === 200) {
          return response.result.teachers;
        }
      });
    },
    // eslint-disable-next-line no-unused-vars
    getAllTeacherData({ commit }) {
      return Service.Teachers.onGetAllTeacherData().then((response) => {
        if (response.code === 200) {
          return response.result.teachers;
        }
      });
    },
  },
};

export default Teachers;
