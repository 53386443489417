<template>
  <div id="CourseContent" class="mx-3 my-3">
    <Loading :loading="isLoading"></Loading>
    <b-row class="text-center mt-3">
      <b-col lg="12" md="12" sm="12" cols="12">
        <!--        <img :src="img" width="100%" height="auto" style="max-width: 1080px" />-->
      </b-col>
    </b-row>
    <b-row>
      <b-col class="my-3">
        <b style="font-size: 26px" class="">{{ this.course.name }}</b>
        <a :href="shareUrl" target="_blank" style="padding-left: 10px">
          <i class="bi bi-share"></i
        ></a>
      </b-col>
    </b-row>
    <b-row class="text-center">
      <b-col lg="8" md="12" sm="12" cols="12" class="my-3">
        <!-- <button class="btn btn-primary"></button> -->
        <vue-plyr>
          <video controls playsinline @ended="videoEnd" >
            <source
              v-if="isGetContent"
              size="1080"
              :src="course.intro_video + '?random=' + Math.random()"
              type="video/mp4"
            />
          </video>
        </vue-plyr>
      </b-col>
      <b-col lg="4" md="12" sm="12" cols="12" class="my-3">
        <teacher
          :lessons="course.lessons"
          :course="course"
          :dataTeacher="course.creator"
          :courseStatus="courseStatus"
          @checkCourseStatus="checkCourseStatus"
          ref="teacherCard"
        />
        <template v-if="courseStatus">
          <div class="text-left py-1">
            <span>
              คุณจะได้รับใบประกาศนียบัตร เมื่อสอบได้คะแนนเกิน
              {{ course.quiz_score_minimum_percent || 100 }}% ของคะแนนเต็ม
            </span>
          </div>
          <div class="text-left py-1">
            <span> การเรียน : </span>
            <span style="color: #ff6060">{{ `${subjectProgress} %` }}</span>
          </div>
          <div class="text-left py-1">
            <span> คะแนนสอบของคุณ : </span>
            <span style="color: #ff6060">{{
              `${allScoreActive}/${allAnswerScore}`
            }}</span>
          </div>
          <div class="text-left py-1">
            <span>การวัดผล : </span>
            <span style="color: #ff6060">{{
              passCondition(courseStatus.status)
            }}</span>
          </div>
          <div v-if="courseStatus.status === 2">
            <button
              class="btn btn-block main-orange-btn-outline text-center"
              @click="downloadCertificate"
            >
              Download ใบประกาศนียบัตร
            </button>
          </div>
        </template>
      </b-col>
    </b-row>

    <b-row>
      <b-col lg="9" md="12" sm="12" cols="12">
        <div>
          <b-tabs content-class="mt-5" align="left">
            <b-tab title="บทเรียน" active>
              <p v-html="this.course.description"></p>
              <b>จุดประสงค์</b>
              <p v-html="this.course.objective"></p>
              <div class="d-flex justify-content-between align-items-center">
                <b>เนื้อหาในคอร์สเรียน</b>
                <button @click="continueLesson"
                  v-if="(course || course.progress) && checkEnroll(course.enroll)" class="btn main-orange-btn-outline mt-3" >
                  เรียนต่อ
                </button>
                <button
                  v-else class="btn btn-secondary mt-3" >
                  เรียนต่อ
                </button>
              </div>

              <LessonList
                :lessons="course.lessons"
                :answerCourse="answerCourse"
                :courseStatus="courseStatus"
                :lessonProgress="progressResponse"
                :enrollCheck="checkEnroll(course.enroll)"
              />
            </b-tab>
            <b-tab title="คำถามที่พบบ่อย">
              <FAQ v-if="course.faq && course.faq.length >0" :faq="course.faq"></FAQ>
            </b-tab>
            <b-tab title="ถาม - ตอบ ครูผู้สอน">
              <QA @save="saveQA" :qa="qa" :action-response="qa_is_success"></QA>
            </b-tab>
          </b-tabs>
        </div>
      </b-col>
      <b-col lg="3" md="12" sm="12" cols="12">
        <div
          v-if="
            course.relate_courses && course.relate_courses.length > 0
          "
        >
          <div class="text-center">
            <span class="font-weight-bold">คอร์สเรียนที่เกี่ยวข้อง</span>
          </div>
          <div class="py-2">
            <CourseCard
              v-for="relatedCourse in course.relate_courses??[]"
              class="my-2"
              :key="relatedCourse.id"
              :id="relatedCourse.id"
              :header="relatedCourse.name"
              :content="relatedCourse.description"
              :image="
                relatedCourse.banner_image
                  ? relatedCourse.banner_image
                  : noImage
              "
            />
          </div>
        </div>
      </b-col>
    </b-row>
  </div>
</template>
<script>
import img2 from "./../../public/temp.jpg";
import { mapState } from "vuex";
import img from "../assets/image/mock/overview.png";
import teacher from "@/components/teacher";
import Loading from "@/components/Loading.vue";
import FAQ from "@/components/FAQ.vue";
import QA from "@/components/QA.vue";
import LessonList from "@/components/Lessons/LessonList";
import noImage from "../assets/image/mock/no-image.png";
import CourseCard from "@/components/CourseCard.vue";
import { domain } from "../util/Constants";
import Swal from "sweetalert2";
import moment from "moment";

// import _ from "lodash";

export default {
  name: "CourseContent",
  data() {
    return {
      img: img,
      img2: img2,
      isLoading: false,
      poster_video: img,
      isPlaying: false,
      timer: null,
      courseStatus: false,
      answerCourse: null,
      isGetContent: false,
      progressResponse: null,
      noImage,
      shareUrl: "",
      qa: [],
      qa_is_success:null,
    };
  },
  computed: {
    ...mapState({
      course: (state) => {
        return state.Courses.course;
      },
      userData: (state) => state.Auth.userData,
      access_token: (state) => state.Auth.access_token,
    }),
    allAnswerScore() {
      if (!this.course.lessons) {
        return 0;
      }

      const questions = this.course.lessons.filter(
        (lesson) => lesson.type === "quiz"
      );
      const fullScore = questions.reduce((acc, cur) => {
        if (!cur.question_sets) {
          return acc;
        } else if (
          !cur.question_sets.pass_used &&
          cur.question_sets.test_type === "pretest"
        ) {
          return acc;
        }
        let score =
          cur.question_sets.max_show_question ?? cur.question_sets.full_score;
        return acc + +score;
      }, 0);
      return fullScore;
    },
    subjectProgress() {
      if (!this.courseStatus || !this.courseStatus.progress) {
        return 0;
      } else {
        return this.courseStatus.progress;
      }
    },
    allScoreActive() {
      if (!this.answerCourse) {
        return 0;
      }
      const fullScore = this.answerCourse.result.reduce((acc, cur) => {
        if (!cur.question_set) {
          return acc;
        }
        if (
          cur.question_set.pass_used &&
          cur.question_set.test_type != "pretest"
        ) {
          return acc + +cur.user_score;
        } else {
          return acc;
        }
      }, 0);
      return fullScore;
    },
    passCourse() {
      const percentPass = this.course.quiz_score_minimum_percent || 100;
      const percentScore = (this.allScoreActive / this.allAnswerScore) * 100;
      if (percentScore >= percentPass) {
        return true;
      } else {
        return false;
      }
    },
  },
  async mounted() {
    this.isLoading = true;
    await this.getContent();
    await this.checkCourseStatus();
    await this.getQA();
    this.isGetContent = true;

  },
  created() {
    // this.$store.dispatch("Courses/clearCourse", []);
    this.shareUrl =
      "https://www.facebook.com/sharer/sharer.php?u=" + window.location.href;
  },
  components: { teacher, LessonList, Loading, CourseCard, FAQ, QA },
  methods: {
    passCondition(status) {
      switch (status) {
        case 1:
          return "ไม่ผ่านเกณฑ์";
        case 2:
          return "ผ่านเกณฑ์";
        case 3:
          return "ไม่ผ่าน เนื่องจากทำแบบทดสอบเกินกำหนด";
      }
    },
    continueLesson() {
      let path = `/lessons`;
      const progress = this.course.progress;
      if (progress) {
        this.$router.push({
          path: path,
          query: {
            courseId: progress.subject_id,
            lessonId: progress.lesson_id,
            sec: progress.lesson_time_sec,
          },
        });
      }
    },
    downloadCertificate() {
      const url = `${domain}certificate-template?user_id=${this.userData.id}&subject_id=${this.course.id}&enroll_id=${this.course.enroll[0].id}`;
      window.open(url, "_blank");
    },
    async checkCourseStatus() {
      const model = {
        user_id: this.userData.id,
        subject_id: this.course.id,
      };
      let response;
      try {
        response = await this.$store.dispatch("Courses/checkCourseStatus", {
          model,
          accessToken: this.access_token,
        });

        this.courseStatus = response;
      } catch (error) {
        this.courseStatus = null;
      }finally {
        this.isLoading = false;
      }

      let answerResponse;
      try {
        answerResponse = await this.$store.dispatch("Courses/getCourseAnswer", {
          id: this.course.id,
          accessToken: this.access_token,
          enrollId: this.courseStatus.enroll_id,
        });
        this.answerCourse = answerResponse;
      } catch (error) {
        this.answerCourse = false;
      }

      await this.getLessonProgress();
      
    },
    async getLessonProgress() {
      let progressResponse;
      try {
        progressResponse = await this.$store.dispatch(
          "Courses/onGetLessonProgess",
          {
            subjectId: this.course.id,
            enrollId: this.courseStatus.enroll_id,
            accessToken: this.access_token,
          }
        );
        this.progressResponse = progressResponse;
      } catch (error) {
        this.progressResponse = false;
      }
      
    },
    async getContent() {
      const id = this.$route.query.id;
      if (id) {
        await this.$store.dispatch("Courses/getCourse", {
          id: id,
          user_id: this.userData.id,
        });
        this.$refs.teacherCard.countLesson();
      } else {
        this.$router.push("/");
      }
    },
    async getQA() {
      this.qa = await this.$store.dispatch("QA/getAll", {
        subject_id: this.course.id,
      });
    },
    async saveQA(data) {
      this.isLoading = true;
      await this.$store
        .dispatch("QA/save", {
          ...data,
          subject_id: this.course.id,
        })
        .then(() => {
          this.qa_is_success = true;
          // Swal.fire("สำเร็จ!", "ส่งคำถามเรียบร้อย", "success");
          Swal.fire({
            title: 'ส่งคำถามเรียบร้อย.',
            confirmButtonText: 'ตกลง',
            confirmButtonColor: '#2778c4',
            allowOutsideClick: false,
            allowEscapeKey: false
          });

        })
        .catch(() => {
          this.qa_is_success = false;
          Swal.fire("ผิดพลาด!", "ไม่สามารถส่งคำถามได้", "error");
        })
        .finally(()=>{
          this.isLoading = false;
        });
    },
    checkEnroll(enrolls) {
      let rs = false;

      if (!Array.isArray(enrolls) || (enrolls && enrolls.length === 0)) {
        this.noEnroll = true;
        rs = false;
      } else {
      const enrollsActive = enrolls.filter((enroll) => enroll.status);
      if (enrollsActive && enrollsActive.length <= 0) {
          rs = false;
        } else {
            const now = moment();
          // enrollsActive.forEach((enroll) => {
            const enroll = enrolls[0]??false;
            const start_at = moment(enroll.register_start);
            const end_at = moment(enroll.register_end);
            
            const course_start_at = moment(enroll.course_start);
            const course_end_at = moment(enroll.course_end);

            if( now.isBetween(start_at, end_at) && now.isBetween(course_start_at, course_end_at) ){
            // if( (start_at && end_at) && (course_start_at && course_end_at) ){
              if(this.course.access_rights === 'public' || (this.course.access_rights === 'private' && this.course.approve_right !== null) ){
                
                if(enroll.user_enroll && enroll.user_enroll.length > 0 && course_end_at.isAfter(now)){
                  rs = true;
                }else if ( enroll.user_enroll && enroll.user_enroll.length == 0 && end_at.isAfter(now)  ) { 
                  rs = false;
                } 
              }
            }
            // console.log('rs : ',rs)
          // });
        }
      }
      return rs;
    },
    videoEnd(){
      location.reload();
    }
  },
};
</script>
<style lang="scss"></style>
