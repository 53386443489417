<template>
  <div>
    <b-card :header="question.title" header-tag="header">
      <b-card-text v-if="question.description !== '<p>-</p>'">
        {{ question.description }}
      </b-card-text>
      <b-card-text v-if="question.photo">
        <img :src="question.photo" alt="" />
      </b-card-text>
      <b-card-text>
        <b-form-group v-slot="{ ariaDescribedby }">
          <b-form-checkbox-group
            :id="`checkbox-group-${question.id}`"
            v-model="selected"
            :aria-describedby="ariaDescribedby"
            :name="`checkbox-group-${question.id}`"
          >
            <div class="row">
              <div
                v-for="item in question.answers"
                :key="item.id"
                class="p-2 col-12 col-md-6 mt-3"
              >
                <div
                  @click="clickAnswer(item)"
                  class="btn text-left"
                  style="width: 100%"
                >
                  <b-form-checkbox :value="item.id">{{
                    item.answer
                  }}</b-form-checkbox>
                </div>
              </div>
            </div>
          </b-form-checkbox-group>
        </b-form-group>
      </b-card-text>
    </b-card>
  </div>
</template>

<script>
export default {
  props: {
    question: {
      default: [],
    },
  },
  methods: {
    clickAnswer(answer) {
      const model = {
        choiceId: answer.answer,
        questionId: this.question.id,
        answer: this.selected,
        isCorrect: answer.is_correct,
      };
      this.$emit("clickAnswer", model);
      // const recentStete = this.$refs[answer.id][0].$refs.input.checked;
      // this.$refs[answer.id][0].$refs.input.checked = !recentStete;
    },
  },
  data() {
    return {
      selected: [],
    };
  },
};
</script>

<style></style>
