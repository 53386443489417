<template>
  <b-container fluid>
    <div class="row">
      <div
        v-for="item in allCourse"
        :key="item.id"
        :class="item.subject ? 'p-3 col-12 col-sm-12 col-md-6' : ''"
      >
        <div v-if="item.subject" class="mt-3">
          <div class="card-test-result text-center">
            <img class="mx-auto" src="../assets/image/ribbon.png" alt="" />
            <div class="mt-4">
              <h3>{{ userData.first_name }} {{ userData.last_name }}</h3>
            </div>
            <b-row>
              <b-col cols="12" sm="12" md="12" lg="6">
                <div>เรียนบทเรียนไปแล้ว</div>
                <div class="test-result-score">
                  {{ item.progress ? item.progress : 0 }} %
                </div>
                <div class="mt-2">จากคอร์สเรียน</div>
              </b-col>
              <b-col cols="12" sm="12" md="12" lg="6">
                <div>ทำคะแนนบททดสอบได้</div>
                <div class="test-result-score">{{ testProgress(item) }} %</div>
                <div class="mt-2">จากคอร์สเรียน</div>
              </b-col>
            </b-row>
            <div class="my-3">
              <b>{{ item.subject ? item.subject.name : "" }}</b>
            </div>
            <b-container fluid>
              <b-row>
                <b-col align-self="end" cols="12" class="m-1">
                  <button
                    :disabled="passCondition(item)"
                    class="btn btn-block main-orange-btn-outline block"
                    @click="certDownload(item)"
                  >
                    พิมพ์ใบประกาศนียบัตร
                  </button>
                </b-col>
              </b-row>
            </b-container>
          </div>
        </div>
      </div>
    </div>
  </b-container>
</template>

<script>
import { mapState } from "vuex";
import { domain } from "../util/Constants";

export default {
  props: ["allCourse"],
  computed: {
    ...mapState({
      allScore: (state) => state.Question.allScore,
      userData: (state) => state.Auth.userData,
      access_token: (state) => state.Auth.access_token,
    }),
  },
  created() {
    //this.getUserScore();
  },
  methods: {
    passCondition(item) {
      if (item.status !== 2) return true;
      //else if (this.testProgress(item) >= 100) return false;
      else return false;
    },
    testProgress(item) {
      if (item) {
        return item.max_score > 0
          ? ((item.score / item.max_score) * 100).toFixed(2)
          : 0;
      } else {
        return 0;
      }
    },
    certDownload(userEnroll) {
      const url = `${domain}certificate-template?user_id=${this.userData.id}&subject_id=${userEnroll.subject_id}&enroll_id=${userEnroll.enroll_id}`;
      window.open(url, "_blank");
    },
    progressPercent(userProgress) {
      if (!userProgress) {
        return 0;
      }
      let userProgressDone = userProgress.filter((user) => user.status == 1);

      return ((userProgressDone.length / userProgress.length) * 100).toFixed(2);
    },
    async getUserScore() {
      await this.$store
        .dispatch("Question/onGetUserScore", {
          user_id: this.userData.id,
          accessToken: this.access_token,
        })
        // .then((response) => {
        //   if (response.code == 200) {
        //   }
        // });
    },
  },
};
</script>

<style></style>
