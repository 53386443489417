<template>
  <div>
    <b-card
      :header="`${question.prefix} ${question.title}`"
      header-tag="header"
    >
      <b-card-text v-if="question.photo">
        <img :src="question.photo" alt="" />
      </b-card-text>
      <!-- <b-card-text v-if="lastAnswer">
        <div class="border rounded p-2 text-secondary">
          <span> คำตอบครั้งที่แล้ว : {{ lastAnswer.answer }} </span>
        </div>
      </b-card-text> -->
      <b-card-text>
        <b-form-textarea
          id="textarea"
          v-model="text"
          placeholder=""
          rows="4"
          max-rows="6"
          @input="inputHandler"
        ></b-form-textarea>
      </b-card-text>
    </b-card>
  </div>
</template>

<script>
export default {
  props: ["question", "userAnswer"],
  computed: {
    text: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit("input", value);
      },
    },
    lastAnswer() {
      if (!this.userAnswer) {
        return null;
      }
      return this.userAnswer.answer_json.find(
        (answer) => answer.question_id == this.question.id
      );
    },
  },
  data() {
    return {
      // text: null,
      // question: [
      //   { name: "คำตอบที่ 1" },
      //   { name: "คำตอบที่ 2" },
      //   { name: "คำตอบที่ 3" },
      //   { name: "คำตอบที่ 4" },
      // ],
    };
  },
  methods: {
    inputHandler(value) {
      const model = {
        // choiceId: this.question.id,
        question_id: this.question.id,
        answer: value,
        // isCorrect: answer.is_correct,
      };
      this.$emit("answer", model);
    },
  },
};
</script>

<style></style>
