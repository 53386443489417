<template>
  <div id="topNav">
    <b-navbar toggleable="lg" class="py-0">
      <b-navbar-nav id="sideMenuMobile"> <sideMenuMobile /> </b-navbar-nav>
      <b-navbar-brand id="logo" class="navbar-brand" href="/"
        ><img :src="logo" height="auto" width="150" alt="E-learning"
      /></b-navbar-brand>

      <!-- <b-navbar-nav id="navSearch" class="py-0" style="display: none">
        <b-row>
          <b-button
            size="sm"
            pill
            class="btn btn-light search_btn btnHover"
            type="button"
            @click="hideSearchInput"
          >
            <b-icon-arrow-left></b-icon-arrow-left
          ></b-button>
          <Search />
        </b-row>
      </b-navbar-nav>
      <b-navbar-nav class="ml-auto mr-2" id="btnGetNavSearch">
        <b-button
          @click="getSearchInput"
          id="btnNavSearch"
          class="btn btn-light search_btn btnHover"
        >
          <b-icon-search></b-icon-search>
        </b-button>
      </b-navbar-nav>
      <b-navbar-nav id="searchLg"> <Search /></b-navbar-nav> -->

      <b-navbar-toggle target="nav_sidebar" v-b-toggle.nav_sidebar>
        <b-icon-list variant="dark"></b-icon-list>
      </b-navbar-toggle>

      <b-navbar-nav class="ml-auto" id="navBtn_desktopSide">
        <span v-if="this.access_token">
          <b-nav-item-dropdown right>
            <!-- Using 'button-content' slot -->
            <template #button-content>
              <UserPanel
                :image="userdata.profile_image"
                :department="userdata.department"
                :name="`${userdata.first_name} ${userdata.last_name}`"
                :id="userdata.id"
              ></UserPanel>
            </template>
            <b-dropdown-item @click="goto('userProfile')" href="#"
              >โปรไฟล์</b-dropdown-item
            >
            <b-dropdown-item @click="logout(access_token)"
              >ออกจากระบบ</b-dropdown-item
            >
          </b-nav-item-dropdown>
        </span>
        <span v-else>
          <b-button class="btn btn-light mx-1 btnHover btnNav" href="/login"
            >เข้าสู่ระบบ</b-button
          >
          <b-button class="btn btn-light mx-1 btnHover btnNav" href="/register"
            >ลงทะเบียน</b-button
          >
        </span>
      </b-navbar-nav>
    </b-navbar>
    <b-sidebar id="nav_sidebar" style="color: white" right shadow is-nav>
      <b-navbar-nav class="ml-auto">
        <span v-if="this.access_token">
          <b-nav-item-dropdown right>
            <!-- Using 'button-content' slot -->
            <template #button-content>
              <UserPanel
                :image="userdata.profile_image"
                :department="userdata.department"
                :name="`${userdata.first_name} ${userdata.last_name}`"
                :id="userdata.id"
              ></UserPanel>
            </template>
            <b-dropdown-item @click="goto('userProfile')" href="#"
              >โปรไฟล์</b-dropdown-item
            >
            <b-dropdown-item @click="logout()">ออกจากระบบ</b-dropdown-item>
          </b-nav-item-dropdown>
        </span>
        <span v-else>
          <b-button class="btn btn-light mx-1 btnHover btnNav" href="/login"
            >เข้าสู่ระบบ</b-button
          >
          <b-button class="btn btn-light mx-1 btnHover btnNav" href="/register"
            >ลงทะเบียน</b-button
          >
        </span>
      </b-navbar-nav>
    </b-sidebar>
  </div>
</template>
<script>
import logo from "./../../public/logo_2.png";
// import Search from "@/components/search";
import sideMenuMobile from "@/components/sideMenuMobile";
import UserPanel from "@/components/UserPanel";
import $ from "jquery";
import { mapState } from "vuex";
import Swal from "sweetalert2";
export default {
  name: "topNav",
  components: {
    sideMenuMobile,
    UserPanel,
    // Search
  },
  data() {
    return {
      logo: logo,
    };
  },
  computed: {
    ...mapState({
      access_token: (state) => state.Auth.access_token,
      username: (state) => state.Auth.username,
      userdata: (state) => state.Auth.userData,
    }),
  },
  mounted() {
    $(document).mouseup(function (e) {
      let width = $(window).width();
      let input_search = $("#input_search");
      let nav_sidebar = $("#nav_sidebar");
      let sideMenuMobile = $("#sidebar-1");
      // let navBtn_desktopSide = $("#navBtn_desktopSide");
      if (
        !input_search.is(e.target) &&
        input_search.has(e.target).length === 0 &&
        width <= 991
      ) {
        document.getElementById("sideMenuMobile").style.display = "block";
        document.getElementById("input_search").focus();
        document.getElementById("input_search").value = null;
        document.getElementById("logo").style.display = "block";
        document.getElementById("btnGetNavSearch").style.display = "block";
        document.getElementById("navSearch").style.display = "none";
        document.getElementById("btnNavSearch").style.display = "block";
      }
      if (
        !nav_sidebar.is(e.target) &&
        nav_sidebar.has(e.target).length === 0 &&
        width <= 991
      ) {
        document.getElementById("nav_sidebar").style.display = "none";
      }
      if (
        !sideMenuMobile.is(e.target) &&
        sideMenuMobile.has(e.target).length === 0 &&
        width <= 991
      ) {
        document.getElementById("sidebar-1").style.display = "none";
      }
    });
  },
  methods: {
    logout() {
      this.$store.dispatch("Auth/logout").then((res) => {
        if (res == 200) {
          this.$router.push("/");
          Swal.fire("เรียบร้อย!", "ออกจากระบบเรียบร้อย", "success");
        }
      });
    },
    goto($event) {
      this.$router.push({ name: $event });
    },
    getSearchInput() {
      document.getElementById("sideMenuMobile").style.display = "none";
      document.getElementById("logo").style.display = "none";
      document.getElementById("btnGetNavSearch").style.display = "none";
      document.getElementById("navSearch").style.display = "block";
    },
    hideSearchInput() {
      document.getElementById("sideMenuMobile").style.display = "block";
      document.getElementById("input_search").focus();
      document.getElementById("input_search").value = null;
      document.getElementById("logo").style.display = "block";
      document.getElementById("btnGetNavSearch").style.display = "block";
      document.getElementById("navSearch").style.display = "none";
      document.getElementById("btnNavSearch").style.display = "block";
    },
  },
};
</script>
<style lang="scss">
#topNav {
  border-bottom: 1px rgba(200, 187, 187, 0.2) solid !important;
  box-shadow: 0 4px 8px 0 rgba(200, 187, 187, 0.2),
    0 6px 20px 0 rgba(174, 168, 168, 0.19);
}
//a#logo.navbar-brand {
//  position: relative;
//  left: calc(30%);
//  //margin: 0 auto;
//}
.btnNav {
  background-color: transparent !important;
  border: none !important;
}
#btnGetNavSearch {
  border: none !important;
}
@media screen and (min-width: 991px) {
  #menu {
    display: none;
  }
  .navSearch {
    left: -50px !important;
  }
  #btnGetNavSearch {
    display: none;
  }
  #sideMenuMobile {
    display: none;
  }
}
@media screen and (max-width: 991px) {
  #searchLg {
    display: none;
  }
  #navBtn_desktopSide {
    display: none;
  }
}
</style>
