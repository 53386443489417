<template>
  <div class="fill-height">
    <TopNav />
    <div class="container mt-5">
      <div class="row">
        <div class="col-md-3 col-12">
          <img
            v-if="userData.profile_image"
            class="mr-3 fluid rounded-circle"
            style="width: 150px; height: 150px"
            :src="`${userData.profile_image}`"
            alt=""
          />
        </div>
        <div class="col-md-9 col-12">
          <div class="row justify-content-between align-items-center">
            <div class="col-md-4 col-12">
              <h4>{{ userData.first_name }} {{ userData.last_name }}</h4>
            </div>
            <div class="col-md-8 col-12 row">
              <div class="col-md-4 col-12 mt-2">
                <button
                  @click="toChangePassword"
                  class="btn main-orange-btn btn-block"
                >
                  เปลี่ยนรหัสผ่าน
                </button>
              </div>
              <div class="col-md-4 col-12 mt-2">
                <button
                  @click="$bvModal.show('bv-modal')"
                  class="btn main-orange-btn btn-block"
                >
                  แก้ไขโปรไฟล์
                </button>
              </div>
              <div class="col-md-4 col-12 mt-2">
                <button
                  @click="exportProfile"
                  class="btn main-orange-btn btn-block"
                >
                  พิมพ์ประวัติ
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="horizon-line my-5"></div>
    <div class="container">
      <b-tabs content-class="mt-5" align="left">
        <b-tab title="คอร์สของฉัน" active>
          <div class="row">
            <div
              v-for="item in courseStatus"
              :key="item.id"
              class="col-md-3 col-12 mt-2"
            >
              <button
                @click="getUserCourse(item.id)"
                :class="
                  courseStatusSelected === item.id
                    ? 'btn btn-block main-black-btn-active'
                    : 'btn btn-block main-black-btn'
                "
              >
                {{ item.name }}
              </button>
            </div>
          </div>
          <div class="row mt-5">
            <div
              v-for="item in courseList"
              :key="item.id"
              :class="item.subject ? 'col-md-4 col-12 subject-card my-2' : ''"
              style="max-height: 300px"
            >
              <CourseCard
                v-if="item.subject"
                :id="item.subject.id"
                :header="item.subject.name"
                :content="item.subject.description"
                :image="item.subject.banner_image"
              />
            </div>
          </div>
        </b-tab>
        <b-tab title="ผลการเรียน">
          <div class="row mt-5">
            <div class="col-12">
              <TestResultCard :allCourse="allCoursesNostatus" />
            </div>
          </div>
        </b-tab>
      </b-tabs>
    </div>
    <b-modal size="xl" centered id="bv-modal" hide-header hide-footer>
      <EditUserProfile
        v-if="userData"
        @modalClosed="onModalClose"
        :propData="userData"
      />
    </b-modal>
    <b-modal size="sm" centered id="bv-change-password" hide-header hide-footer>
      <ChangePassword @modalClosed="onChangePasswordClose" />
    </b-modal>
  </div>
</template>

<script>
import { imageDomain } from "../util/Constants";
import TopNav from "@/components/topNav.vue";
import CourseCard from "@/components/CourseCard.vue";
import TestResultCard from "@/components/TestResultCard.vue";
import EditUserProfile from "@/components/EditUserProfile.vue";
import ChangePassword from "@/components/ChangePassword.vue";
import { mapState } from "vuex";
import { domain } from "../util/Constants";

export default {
  components: {
    TopNav,
    CourseCard,
    TestResultCard,
    ChangePassword,
    EditUserProfile,
  },
  data() {
    return {
      userModel: null,
      courseStatusSelected: 1,
      courseList: [],
      imageDomain: imageDomain,
      courseStatus: [
        { id: 1, name: "สมัครเรียนแล้ว" },
        { id: 2, name: "เรียนจบแล้ว" },
      ],
      genderOptions: [
        { value: "male", name: "ชาย" },
        { value: "female", name: "หญิง" },
        { value: "other", name: "ไม่ระบุ" },
      ],
    };
  },
  async created() {
    await this.getUserCourse(this.courseStatusSelected);
    await this.getUserCourseNostatus();
    this.userModel = {
      id: this.userData.id,
      first_name: this.userData.first_name,
      last_name: this.userData.last_name,
      email: this.userData.email,
      phone: this.userData.phone,
      citizen_id: this.userData.citizen_id,
      birth_date: this.userData.birth_date,
      address: this.userData.address,
      gender: this.userData.gender,
    };
  },
  computed: {
    ...mapState({
      userData: (state) => state.Auth.userData,
      access_token: (state) => state.Auth.access_token,
      allCourse: (state) => state.Courses.allCourses,
      allCoursesNostatus: (state) => state.Courses.allCoursesNostatus,
    }),
  },
  methods: {
    toChangePassword() {
      this.$bvModal.show("bv-change-password");
    },
    exportProfile() {
      if (this.userData) {
        window.open(
          `${domain}export-user-profile/${this.userData.id}`,
          "_blank"
        );
      }
    },
    onModalClose() {
      this.$bvModal.hide("bv-modal");
    },
    onChangePasswordClose() {
      this.$bvModal.hide("bv-change-password");
    },
    convertGender(engText) {
      let gender = this.genderOptions.find((x) => x.value === engText);
      return gender ? gender.name : "";
    },
    async getUserCourse(status) {
      this.courseStatusSelected = status;
      await this.$store
        .dispatch("Courses/getUserCourse", {
          model: {
            user_id: this.userData.id,
            status_id: status,
          },
          accessToken: this.access_token,
        })
        .then((response) => {
          if (response.code == 200) {
            this.courseList = this.allCourse;
          }
        })
        .catch((err) => {
          if (err.response.status == 401) {
            this.$store.dispatch("Auth/logout").then(() => {
              this.$router.push("/login");
            });
          }
        });
    },
    async getUserCourseNostatus() {
      await this.$store
        .dispatch("Courses/getUserCourseNostatus", {
          model: {
            user_id: this.userData.id,
            status_id: null,
          },
          accessToken: this.access_token,
        })
        .catch((err) => {
          if (err.response.status == 401) {
            this.$store.dispatch("Auth/logout").then(() => {
              this.$router.push("/login");
            });
          }
        });
    },
  },
};
</script>

<style></style>
