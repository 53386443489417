<template>
  <b-modal
    size="sm"
    centered
    id="loading-modal"
    :no-close-on-backdrop="true"
    hide-header
    hide-footer
  >
    <div style="height: 100px">
      <loading
        :active.sync="loading"
        :is-full-page="false"
        loader="dots"
      ></loading>
    </div>
  </b-modal>
</template>

<script>
import loading from "vue-loading-overlay";
import "vue-loading-overlay/dist/vue-loading.css";

export default {
  name: "Loading",
  components: {
    loading,
  },
  props: {
    loading: {
      default: false,
    },
  },
  watch: {
    loading: function (newVal) {
      if (newVal) this.$bvModal.show("loading-modal");
      else this.$bvModal.hide("loading-modal");
    },
  },
};
</script>

<style scoped>
.rad-15 {
  border-radius: 15px !important;
}
</style>
