<template>
  <div class="coursesPage font-17">
    <Loading :loading="isLoading"></Loading>
    <topNav v-if="menus.find((menu) => menu.name == 'topNav')" />
    <b-row class="justify-content-md-center">
      <b-col v-if="menus.find((menu) => menu.name == 'sideMenu')" lg="2" xl="2" class="layout-side-menu px-0 ">
        <sideMenu />
      </b-col>
      <b-col lg="10" xl="10" 
      class="layout-content"
      >
        <b-row>
          <b-col>
            <p style="font-size: 24px" class=" pl-10">
              <b> {{ title }}</b>
            </p>
          </b-col>
        </b-row>
        <b-row 
          align-v="stretch"
          class="mx-3  mx-sm-0 ml-sm-0 px-sm-3"
        >
          <b-col cols="12" sm="6" md="4" lg="3" xl="3"
            v-for="item in allCourses"
            :key="item.id"
            class="subject-card my-2 mb-10 col-xxl-4 col-xxl-5 px-0 px-sm-3"
          >
          <CourseCard
              v-if="
                item.subject_payments_pending &&
                item.subject_payments_pending.length > 0
              "
              :id="item.id"
              :header="item.name"
              :content="item.description"
              :image="item.banner_image ? item.banner_image : noImage"
              :paid_type="item.paid_type"
              :price="item.price"
              :status="item.subject_payments_pending[0].status"
              :enroll="item.enroll? item.enroll[0]||false : false"
              :approve_right="item.approve_right"
              :access_rights="item.access_rights"
              @open-modal="openModal"
            />

            <CourseCard
              v-else
              :id="item.id"
              :header="item.name"
              :content="item.description"
              :image="item.banner_image ? item.banner_image : noImage"
              :paid_type="item.paid_type"
              :price="item.price"
              :status="null"
              :enroll="item.enroll? item.enroll[0]||false : false"
              :approve_right="item.approve_right"
              :access_rights="item.access_rights"
              @open-modal="openModal"
            />
            <b-modal content-class="qr-modal-size"   centered id="qr-modal" hide-header hide-footer >
      <!-- <pre>{{ course.enroll }}</pre> -->
                <div class="text-center pa-5">
                  <div v-if="noEnroll">
                        คอร์สนี้ยังไม่เปิดรับสมัคร
                  <b-button 
                  variant="link" 
                  class="ml-2 p-0 close" 
                  @click="closeModal"
                >
                  <span aria-hidden="true">&times;</span>
                </b-button>
                  </div>
                  <div v-else-if="isEnroll">
                  <h2>สแกน QR Code</h2>
                  <p>สแกน QR Code เพื่อชำระเงิน</p>
                  <b-img :src="qrCode" width="200" height="200" fluid alt="QR Code"></b-img>
                  <div class="mt-3">
                    <h5 class="d-flex justify-content-left">Name</h5>
                    <div class="d-flex justify-content-between">
                      <p class="text-truncate w-75 text-left">{{course.name}}</p>
                      <p>{{course.price}} ฿</p>
                    </div>
                  </div>
                <div class="d-flex flex-column justify-content-center">
                  <b-button variant="secondary" @click="navigateToConfirm"  class="w-100 m-1 z-3">สแกนเสร็จสิ้น</b-button>
                  <b-button @click="closeModal" style="background-color: white;color: gray;" class="w-100 m-1 z-3">ยกเลิก</b-button>
                </div>    
            </div>
              <div v-else>
                  คอร์สนี้ปิดรับสมัครแล้ว
                  <b-button 
                  variant="link" 
                  class="ml-2 p-0 close" 
                  @click="closeModal"
                >
                  <span aria-hidden="true">&times;</span>
                </b-button>
              </div>
              </div>
              </b-modal>
          </b-col>
        </b-row>
      </b-col>
    </b-row>
  </div>
</template>

<script>
// @ is an alias to /src
import topNav from "@/components/topNav.vue";
// import sideMenuMobile from "@/components/sideMenuMobile.vue";
// import sideMenu from "@/components/sideMenu.vue";
import { mapState } from "vuex";
import sideMenu from "@/components/sideMenu.vue";
import Loading from "@/components/Loading.vue";
import CourseCard from "@/components/CourseCard.vue";
import moment from "moment";
import qrCode from "../assets/image/qr_code.jpg";
import Swal from 'sweetalert2';

export default {
  name: "coursesPage",
  props: ["id"],
  components: {
    topNav,
    // sideMenuMobile,
    sideMenu,
    Loading,
    CourseCard,
  },
  computed: {
    ...mapState({
      menus: (state) => {
        return state.Courses.menus;
      },userData: (state) => state.Auth.userData,
      course: (state) => state.Courses.course,
    }),
  },
  async mounted() {
    this.isLoading = true;
    await this.getAllCoursesData();
    this.isLoading = false;
  },
  data() {
    return {
      qrCode :qrCode,
      isLoading: false,
      allCourses: [],
      title: "",
      course_id: 1,
      isEnroll: false,
      noEnroll: false,
      courseStatus: false,
    };
  },
  methods: {
    async getAllCoursesData() {
      const allCoursesResult = await this.$store.dispatch(
        "Courses/getAllCoursesByCategoryData",
        { categoryId: this.id, userId: this.userData.id }
      );

      this.allCourses = allCoursesResult.courses;
      this.title = allCoursesResult.categoryName;
    },  
    async openModal(id) {
      const courseSelect = this.allCourses.find(
        (course) => course.id === id
      );
      let enroll = courseSelect.enroll[0]||null;
      //validate open crouse date
      if (enroll === null) {
        Swal.fire("ขออภัย!", "คอร์ดเรียนนี้ไม่ได้เปิดรับสมัคร.", "error");
        return false;
      }
      //validate course is private & course is approved for this user register
      if (
        courseSelect.approve_right == null &&
        courseSelect.access_rights === "private"
      ) {
        Swal.fire("ขออภัย!", "คุณยังไม่ได้ถูกกำหนดสิทธิ์เข้าใช้งานคอร์ดเรียนนี้.", "error");
        return false;
      }

      if (!this.$store.state.Auth.access_token) {
        this.$router.push({
          path: "/login",
          query: {
            redirect: this.$route.fullPath 
          },
        });
      } else {
          this.course_id = id
          await this.getContent(id)
          this.$bvModal.show('qr-modal');
        }
    },
    closeModal() {
      this.$bvModal.hide('qr-modal'); 
  },
  navigateToConfirm(){
    this.$router.push({ 
      name: "confirmPayment" ,
    params: { id: this.course_id }
    });
  },

   checkEnroll(enrolls) {
    this.isLoading = true;

        if (!Array.isArray(enrolls) || enrolls.length === 0) {
          this.noEnroll = true;
          return false;
        }else {
          this.noEnroll = false;
        const enrollsActive = enrolls.filter((enroll) => enroll.status);
        if (enrollsActive.length <= 0) {
          return false;
        } else {
          const now = moment();

          // enrollsActive.forEach((enroll) => {
            const enroll = enrolls[0];
            const start_at = moment(enroll.register_start);
            const end_at = moment(enroll.register_end);
            const course_start_at = moment(enroll.course_start);
            const course_end_at = moment(enroll.course_end);

            if (now.isBetween(start_at, end_at) && now.isBetween(course_start_at, course_end_at) ) {
              this.isEnroll = true
            }else{
              this.isEnroll = false

            }
          // });

        }
      }
      this.isLoading = false;

    },
    
    async getContent(id) {
      this.isLoading = true;
      try {
        await this.$store.dispatch("Courses/getCourse", {
          id: id,
          user_id: this.userData.id,
        });
        if (this.course){
          await this.checkEnroll(this.course.enroll)
        }else{
          this.noEnroll = true
        }
        
      }
      catch (error) {
        console.log(error);
      }
      this.isLoading = false;

    },
  },
};
</script>
<style scoped>
.coursesPage {
  height: 100%;
  min-height: 100vh;
}
.modal-backdrop {
  opacity: 0.3 !important;
}
.qr-modal-size {
  max-width: 400px; 
}
@media (max-width: 767px) {
  .container {
    padding: 0px;
  }
}
</style>
