import Service from "../../service/index";

const QA = {
    name: "QA",
    stateFactory: true,
    namespaced: true,
    state: {
        qa: []
    },
    mutations: {
        SET_QA: (state, data) => {
            state.qa = data;
        },
        PUSH_ONE_QA: (state, data) => {
            state.qa.unshift(data);
        }
    },
    actions: {
        getAll({ commit }, { subject_id }) {
            return Service.QA.getQA(subject_id).then((response) => {
                commit("SET_QA", response);
                return response;
            });
        },
        save({ commit },  data ) {
            return Service.QA.saveQA(data).then((response) => {
                commit("PUSH_ONE_QA", response.data);
                return response;
            });
        },
    },
}
export default QA;
