import Service from "../service";

const courses = {
  onGetCourses() {
    return Service.get("/courses");
  },
  onGetCourse(id, token, user_id) {
    return Service.get(
      `/courses?id=${id}&user_id=${user_id}&with=lessons|lessons.question_sets|attach|enroll|creator|docAttach|faq|videoAttach|relateCourses|lessons.pdf|lessons.teachers.user`,
      { headers: { Authorization: `Bearer ${token}` } }
    );
  },
  onGetCourseAnswer(id, accessToken, enrollId) {
    return Service.get(`/course-answer/${id}?enroll_id=${enrollId}`, {
      headers: { Authorization: `Bearer ${accessToken}` },
    });
  },
  onGetIndexCourses(id) {
    return Service.get("/index/courses", { params: { user_id: id }});
  },
  getAllCoursesByCategoryData(id,user_id) {
    return Service.get(`/data/courses/category/${id}`, { 
      params: { user_id: user_id } 
  });
  },
  getcCurseManage_Rights(user_id, subject_id) {
    return Service.get("/manage_rights?user_id=" + user_id + "&subject_id=" + subject_id);
  },
  onGetAllCoursesData(id) {
    return Service.get("/data/courses", { params: { user_id: id } });
  },
  onGetIndexTeachers() {
    return Service.get("/index/teachers");
  },
  onGetAllTeachersData() {
    return Service.get("/data/teachers");
  },
  onGetAllCourses(category = null, keyword = null, order = null) {
    return Service.get("/courses?with=lessons|attach|enroll|creator", {
      params: { category_id: category, q: keyword, order: order },
    });
  },
  onGetQuestion() {
    return Service.get("/questions?with=answers|attach");
  },
  onGetUserCourse(model, accessToken) {
    return Service.post(
      "/course/user-course",
      { user_id: model.user_id, status_id: model.status_id },
      { headers: { Authorization: `Bearer ${accessToken}` } }
    );
  },
  onRegisterCourse(data, accessToken) {
    return Service.post("/course/register", data, {
      headers: { Authorization: `Bearer ${accessToken}` },
    });
  },
  onCheckCourseStatus(data, accessToken) {
    return Service.post("/course/check-course-status", data, {
      headers: { Authorization: `Bearer ${accessToken}` },
    });
  },
  onGetBanners() {
    return Service.get("/banners");
  },
  onGetCertificate(userId, subjectId) {
    return Service.get("/certificate-template", {
      params: { user_id: userId, subject_id: subjectId },
    });
  },
  onGetLessonProgess(subjectId, enrollId, accessToken) {
    return Service.get(`/lesson-progress/${subjectId}/${enrollId}`, {
      headers: { Authorization: `Bearer ${accessToken}` },
    });
  },
  onGetMenus() {
    return Service.get(`/menus`);
  },
  onSendPaymentSlip(data, accessToken) {
    return Service.post("/send-slip", data, {
      headers: { Authorization: `Bearer ${accessToken}` },
    });
  },
   

};

export default courses;
