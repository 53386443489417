<template>
  <div class="coursesPage font-17">
    <topNav />
    <!--    <sideMenuMobile />-->
    <!--    <adsCouse />-->
    <div class="container">
      <b-row>
        <b-col lg="12" md="12" sm="12" cols="12">
          <CourseContent />
        </b-col>
      </b-row>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
import topNav from "@/components/topNav.vue";
// import sideMenuMobile from "@/components/sideMenuMobile.vue";
// import sideMenu from "@/components/sideMenu.vue";
import CourseContent from "@/components/CourseContent.vue";

export default {
  name: "coursesPage",
  components: {
    topNav,
    CourseContent,
    // sideMenuMobile,
    // sideMenu,
  },
};
</script>
<style>
.coursesPage {
  height: 100%;
  min-height: 100vh;
}

@media (max-width: 767px) {
  .container {
    padding: 0px;
  }
}
</style>
