<template>
  <div class="user-confirmation">
    <Loading :loading="isLoading"></Loading>
    <div class="py-5 text-center my-auto" style="height: 100vh">
      <div v-if="!isLoading && !confirmation">
        ยืนยันตัวตนผิดพลาด กรุณาลองใหม่อีกครั้ง
      </div>
      <div v-if="!isLoading && confirmation">
        ยืนยันตัวตนสำเร็จ กำลังย้ายไปที่หน้าเข้าสู่ระบบ
      </div>
    </div>
  </div>
</template>
<script>
import Loading from "@/components/Loading.vue";

export default {
  name: "UserConfirmation",
  components: {
    Loading,
  },
  data() {
    return {
      isLoading: false,
      confirmation: false,
    };
  },
  mounted() {
    this.isLoading = true;
    if (!this.$route.query.code) {
      this.isLoading = false;
      setTimeout(() => {
        this.$router.push("/");
      }, 3000);
    }
    this.$store
      .dispatch("Auth/userConfirmation", this.$route.query.code)
      .then(() => {
        this.isLoading = false;
        this.confirmation = true;
        setTimeout(() => {
          this.$router.push("/login");
        }, 3000);
      })
      .catch(() => {
        this.isLoading = false;
        setTimeout(() => {
          this.$router.push("/");
        }, 3000);
      });
  },
};
</script>
