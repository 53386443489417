<template>
  <div class="footer mainFooter">
    <div>contact : elearning.ordpmk@pcm.ac.th</div>
    E-learning CopyRight © 2021
    </div>
</template>
<script>
export default {
  name: "mainFooter",
};
</script>
<style lang="scss">
.mainFooter {
  background-color: #de7033;
  text-align: center;
}
</style>
