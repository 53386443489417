<template>
  <div class="accordion" role="tablist" v-if="faq.length > 0">
    <div v-for="item in faq" :key="item.id">
      <b-button
        @click="clickFAQ(item.id)"
        v-if="selected === item.id"
        class="btn-block py-2 collapse-btn-active"
        v-b-toggle="`collapse-${item.id}`"
      >
        <div class="d-flex justify-content-between">
          <div v-html="item.question"></div>
          <div>
            <i class="bi bi-plus-lg"></i>
          </div>
        </div>
      </b-button>
      <b-button
        @click="clickFAQ(item.id)"
        v-else
        class="btn-block py-2 collapse-btn"
        v-b-toggle="`collapse-${item.id}`"
      >
        <div class="d-flex justify-content-between">
          <div v-html="item.question"></div>
          <div>
            <i class="bi bi-dash-lg"></i>
          </div>
        </div>
      </b-button>
      <b-collapse
        accordion="my-accordion"
        :id="`collapse-${item.id}`"
        class="mt-2"
      >
        <div class="collapse-border">
          <div v-html="item.answer"></div>
        </div>
      </b-collapse>
    </div>
  </div>
</template>

<script>
export default {
  props: ["faq"],
  data() {
    return {
      selected: 0,
    };
  },
  methods: {
    clickFAQ(id) {
      if (id === this.selected) {
        this.selected = 0;
      } else {
        this.selected = id;
      }
    },
  },
};
</script>

<style>
.btn-secondary:focus,
.btn-secondary.focus {
  box-shadow: none !important;
}
</style>
