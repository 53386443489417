<template>
  <div class="fill-height">
    <TopNav />
    <div class="container mt-5">
      <h1>เปลี่ยนรหัสผ่าน</h1>
      <hr class="horizon-line-orange" />
      <div class="row px-3 justify-content-center">
        <div class="col-12 mt-5">
          <label>รหัสผ่านปัจจุบัน</label>
          <input class="form-control" type="password" />
          <a class="orange-text pointer">ลืมรหัสผ่าน?</a>
        </div>
      </div>
      <div class="my-3 grey-background px-3 py-3">
        <div class="row">
          <div class="col-12">
            <label>รหัสผ่านใหม่</label>
            <input class="form-control" type="password" />
          </div>
          <div class="col-12 mt-5">
            <label>ยืนยันรหัสผ่านใหม่</label>
            <input class="form-control" type="password" />
          </div>
        </div>
      </div>
      <div class="row justify-content-center">
        <div class="col-2">
          <button class="btn btn-block main-orange-btn">ยกเลิก</button>
        </div>
        <div class="col-2">
          <button class="btn btn-block main-orange-btn-outline">บันทึก</button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import TopNav from "@/components/topNav.vue";
export default {
  components: {
    TopNav,
  },
};
</script>

<style></style>
