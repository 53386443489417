<template>
  <div class="home">
    <Loading :loading="isLoading"></Loading>
    <topNav v-if="menus.find((menu) => menu.name == 'topNav')" />
    <b-row>
      <b-col lg="2">
        <!--        <sideMenuMobile />-->
      </b-col>
    </b-row>

    <b-row v-if="banners.length > 0" class="mt-3">
      <b-col class="text-center" cols="12">
        <swiper
          ref="carousel"
          @click-slide="handlerSlide()"
          :options="swiperOptions"
        >
          <swiper-slide
            v-for="banner in banners??[]"
            class="pointer"
            :key="banner.id"
          >
            <img
              class="mx-auto"
              :src="`https://elearningadmin.ordpmk.com/.images/${banner.image_url}`"
              style="max-width: 100vw; max-height: 200px"
              alt=""
            />
          </swiper-slide>
          <div class="swiper-pagination" slot="pagination"></div>
        </swiper>
      </b-col>
    </b-row>

    <!-- <adsCouse /> -->
    <b-row class="justify-content-md-center">
      <b-col v-if="menus.find((menu) => menu.name == 'sideMenu')" lg="2" xl="2" class="layout-side-menu px-0 ">
        <sideMenu />
      </b-col>
      <b-col
        lg="10" xl="10"  
        class="layout-content"
      >
        <allTeacher
          v-if="menus.find((menu) => menu.name == 'allTeacher')"
          @search="teacherSearchHandler"
          class=" "
        />
        <b-row
          :class="`${
            menus.find((menu) => menu.name == 'allTeacher') ? 'mt-5 mx-3' : ''
          } `"
          align-content="between"
        >
          <b-col class="my-auto text-left text-md-left" >
            <span class="font-weight-bold" style="font-size: 24px"
              >คอร์สเรียนใหม่ล่าสุด</span
            >
          </b-col>
          <b-col class="my-auto">
            <!-- <Search
              placeholder="ค้นหาคอร์สเรียน..."
              @search="searchCourseHandler"
            /> -->
            <b-button
              variant="link"
              class="float-right"
              @click="goToAllCourses()"
            >
              <span style="color: #ffc107"> ดูทั้งหมด </span>
              <b-icon
                style="color: #ffc107"
                fab
                icon="plus-circle-fill"
                class="close ml-1"
              ></b-icon>
            </b-button>
          </b-col>
          <!-- <b-col class="my-auto text-center text-md-right" cols="12" md="3"> -->
            <!-- <b-button-group>
              <b-button
                @click="reorderCourse('top')"
                :variant="orderType === 'top' ? 'warning' : ''"
                >Top View</b-button
              >
              <b-button
                @click="reorderCourse('newest')"
                :variant="orderType === 'newest' ? 'warning' : ''"
                >Newest</b-button
              >
            </b-button-group> -->
          <!-- </b-col> -->
        </b-row>
        <b-row
          align-v="stretch"
          class="mx-3  mx-sm-0 ml-sm-0 px-sm-3"
        >
          <b-col cols="12" sm="6" md="4" lg="3" xl="3"
            v-for="item in allCourses"
            :key="item.id"
            class="subject-card my-2 mb-10 col-xxl-4 col-xxl-5 px-0 px-sm-3"
          >
            <CourseCard
              v-if="
                item.subject_payments_pending &&
                item.subject_payments_pending.length > 0
              "
              :id="item.id"
              :header="item.name"
              :content="item.description"
              :image="item.banner_image ? item.banner_image : noImage"
              :paid_type="item.paid_type"
              :price="item.price"
              :status="item.subject_payments_pending[0].status"
              :enroll="item.enroll? item.enroll[0]||false : false"
              :approve_right="item.approve_right"
              :access_rights="item.access_rights"
              @open-modal="openModal"
            />

            <CourseCard
              v-else
              :id="item.id"
              :header="item.name"
              :content="item.description"
              :image="item.banner_image ? item.banner_image : noImage"
              :paid_type="item.paid_type"
              :price="item.price"
              :status="null"
              :enroll="item.enroll? item.enroll[0]||false : false"
              :approve_right="item.approve_right"
              :access_rights="item.access_rights"
              @open-modal="openModal"
            />
          <!-- <pre>{{ item }}</pre> -->

          </b-col>
        </b-row>
      </b-col>
    </b-row>
    <b-modal
      content-class="qr-modal-size"
      centered
      id="qr-modal"
      hide-header
      hide-footer
    >
      <div class="text-center pa-5">
        <div v-if="noEnroll">
          คอร์สนี้ยังไม่เปิดรับสมัคร
          <b-button variant="link" class="ml-2 p-0 close" @click="closeModal">
            <span aria-hidden="true">&times;</span>
          </b-button>
        </div>
        <div v-else-if="isEnroll">
          <h2>สแกน QR Code</h2>
          <p>สแกน QR Code เพื่อชำระเงิน</p>
          <b-img
            :src="qrCode"
            width="200"
            height="200"
            fluid
            alt="QR Code"
          ></b-img>
          <div class="mt-3">
            <h5 class="d-flex justify-content-left">Course</h5>
            <div class="d-flex justify-content-between">
              <p class="text-truncate w-75 text-left">{{ course.name }}</p>
              <p>{{ course.price }} ฿</p>
            </div>
          </div>
          <div class="d-flex flex-column justify-content-center">
            <b-button
              variant="secondary"
              @click="navigateToConfirm"
              class="w-100 m-1 z-3"
              >สแกนเสร็จสิ้น</b-button
            >
            <b-button
              @click="closeModal"
              style="background-color: white; color: gray"
              class="w-100 m-1 z-3"
              >ยกเลิก</b-button
            >
          </div>
        </div>
        <div v-else>
          คอร์สนี้ปิดรับสมัครแล้ว
          <b-button variant="link" class="ml-2 p-0 close" @click="closeModal">
            <span aria-hidden="true">&times;</span>
          </b-button>
        </div>
      </div>
    </b-modal>
    <!-- <b-row>
      <b-col lg="10" offset-lg="2">
        <div class="d-flex flex-wrap">
          <div
            v-for="item in allCourses"
            :key="item.id"
            class="subject-card mb-5"
          >
            <CourseCard
              :id="item.id"
              :header="item.name"
              :content="item.description"
              :image="item.banner_image ? item.banner_image : noImage"
            />
          </div>
        </div>
      </b-col>
    </b-row> -->
  </div>
</template>

<script>
// @ is an alias to /src
import bg from "../assets/image/bg.png";
import noImage from "../assets/image/mock/no-image.png";
import CourseCard from "@/components/CourseCard.vue";
import topNav from "@/components/topNav.vue";
// import sideMenuMobile from "@/components/sideMenuMobile.vue";
import sideMenu from "@/components/sideMenu.vue";
import Loading from "@/components/Loading.vue";
import allTeacher from "@/components/allTeacher.vue";
import { mapState } from "vuex";
// import Search from "@/components/search";
import moment from "moment";
import Swal from 'sweetalert2';

import qrCode from "../assets/image/qr_code.jpg";
export default {
  name: "Home",
  data() {
    return {
      qrCode :qrCode,
      data: null,
      bg: bg,
      dataCreator: null,
      categoryId: null,
      noImage: noImage,
      isLoading: false,
      orderType: "top",
      allCourses: [],
      banners: [],
      isEnroll: false,
      noEnroll: false,
      courseStatus: false,
      swiperOptions: {
        loop: true,
        initialSlide: 0,
        autoplay: {
          delay: 1500,
          disableOnInteraction: true,
        },
        speed: 800,
        // If we need pagination
        pagination: {
          el: ".swiper-pagination",
        },

        // Navigation arrows
        navigation: {
          nextEl: ".swiper-button-next",
          prevEl: ".swiper-button-prev",
        },

        // And if we need scrollbar
        scrollbar: {
          el: ".swiper-scrollbar",
        },
      },
    };
  },
  computed: {
    ...mapState({
      // allCourses: (state) => {
      //   return state.Courses.allCourses;
      // },
      menus: (state) => {
        return state.Courses.menus;
      },
      userData: (state) => state.Auth.userData,
      course: (state) => state.Courses.course,
    }),
  },
  async mounted() {
    this.isLoading = true;
    await this.getAllCourses();
    await this.getBanners();
    this.isLoading = false;
  },
  components: {
    topNav,
    CourseCard,
    Loading,
    // sideMenuMobile,
    sideMenu,
    allTeacher,
    // Search,
  },

  methods: {
    async getBanners() {
      const banners = await this.$store.dispatch("Courses/onGetBanners");
      this.banners = banners;
    },
    async teacherSearchHandler(text) {
      this.isLoading = true;
      await this.$store.dispatch("Teachers/TeachersList", {
        search: text,
      });
      this.isLoading = false;
    },
    async searchCourseHandler(text) {
      this.isLoading = true;
      await this.$store.dispatch("Courses/getAllCourse", {
        order: this.orderType,
        keyword: text,
      });
      this.isLoading = false;
    },
    async reorderCourse(type) {
      this.orderType = type;
      this.isLoading = true;
      await this.getAllCourses();
      this.isLoading = false;
    },
    async getAllCourses() {
      // await this.$store.dispatch("Courses/getAllCourse", {
      //   order: this.orderType,
      // });

      this.allCourses = await this.$store.dispatch(
        "Courses/getIndexCourses",
        this.userData.id
      );

    },
    navigateToConfirm() {
      this.$router.push({
        name: "confirmPayment",
        params: { id: this.course_id },
      });
    },
    async goToAllCourses() {
      let path = `/all-courses`;
      await this.$router.push({
        path: path,
      });
    },
    async openModal(id) {
      const courseSelect = this.allCourses.find(
        (course) => course.id === id
      );
      let enroll = courseSelect.enroll[0]||null;
      //validate open crouse date
      if (enroll === null) {
        Swal.fire("ขออภัย!", "คอร์ดเรียนนี้ไม่ได้เปิดรับสมัคร.", "error");
        return false;
      }

      
      //validate course is private & course is approved for this user register
      if (
        courseSelect.approve_right == null &&
        courseSelect.access_rights === "private"
      ) {
        Swal.fire("ขออภัย!", "คุณยังไม่ได้ถูกกำหนดสิทธิ์เข้าใช้งานคอร์ดเรียนนี้.", "error");
        return false;
      }

      if (!this.$store.state.Auth.access_token) {
        this.$router.push({
          path: "/login",
          query: {
            redirect: this.$route.fullPath,
          },
        });
      } else {
        this.course_id = id;
        await this.getContent(id);
        this.$bvModal.show("qr-modal");
      }
    },
    closeModal() {
      this.$bvModal.hide("qr-modal");
    },
    async getContent(id) {
      this.isLoading = true;
      try {
        await this.$store.dispatch("Courses/getCourse", {
          id: id,
          user_id: this.userData.id,
        });

        if (this.course) {
          await this.checkEnroll(this.course.enroll);
        } else {
          this.noEnroll = true;
        }
      } catch (error) {
        console.log(error);
      }
      this.isLoading = false;
    },
    checkEnroll(enrolls) {
      this.isLoading = true;

      if (!Array.isArray(enrolls) || enrolls.length === 0) {
        this.noEnroll = true;
        return false;
      } else {
        this.noEnroll = false;
        const enrollsActive = enrolls.filter((enroll) => enroll.status);
        if (enrollsActive.length <= 0) {
          return false;
        } else {
          const now = moment();
          // enrollsActive.forEach((enroll) => {
            const enroll = enrolls[0];
            const start_at = moment(enroll.register_start);
            const end_at = moment(enroll.register_end);
            
            const course_start_at = moment(enroll.course_start);
            const course_end_at = moment(enroll.course_end);

            if (now.isBetween(start_at, end_at) && now.isBetween(course_start_at, course_end_at) ) {
              this.isEnroll = true;
            } else {
              this.isEnroll = false;
            }
          // });
        }
      }
      this.isLoading = false;
    },
    
  },
};
</script>
<style>
.home {
  height: auto;
  min-height: 100vh;
}
.qr-modal-size {
  max-width: 400px;
}
</style>
