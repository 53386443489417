<template>
  <div id="app">
    <my-upload
      field="img"
      @crop-success="cropSuccess"
      @crop-upload-success="cropUploadSuccess"
      @crop-upload-fail="cropUploadFail"
      v-model="show"
      :params="params"
      :headers="headers"
      img-format="png"
      :noSquare="noSquare"
      :langType="langType"
    ></my-upload>
  </div>
</template>

<script>
import "babel-polyfill"; // es6 shim
import myUpload from "vue-image-crop-upload/upload-2.vue";
export default {
  props: ["isShow"],
  watch: {
    isShow: function (newVal) {
      this.show = newVal;
    },
    show: function (newVal) {
      this.$emit("toggleShow", newVal);
    },
  },
  data() {
    return {
      show: false,
      langType: "en",
      noSquare: true,
      params: {
        token: "123456798",
        name: "avatar",
      },
      headers: {
        smail: "*_~",
      },
      imgDataUrl: "", // the datebase64 url of created image
    };
  },
  components: {
    myUpload,
  },
  methods: {
    toggleShow() {
      this.show = !this.show;
      this.$emit("toggleShow", this.show);
    },
    /**
     * crop success
     *
     * [param] imgDataUrl
     * [param] field
     */
    cropSuccess(imgDataUrl) {
      this.imgDataUrl = imgDataUrl;
      this.$emit("toggleShow", this.show);
      this.$emit("image", imgDataUrl);
    },
    /**
     * upload success
     *
     * [param] jsonData  server api return data, already json encode
     * [param] field
     */
    // cropUploadSuccess(jsonData, field) {
    // },
    /**
     * upload fail
     *
     * [param] status    server api return error status, like 500
     * [param] field
     */
    // cropUploadFail(status, field) {
    // },
  },
};
</script>

<style></style>
