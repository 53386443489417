<template>
  <div class="fill-height">
    <Loading :loading="isLoading"></Loading>
  </div>
</template>

<script>
export default {
  data() {
    return {
      isLoading: false,
    };
  },
  created() {
    this.changeFormatSyncSlide();
  },
};
</script>

<style></style>
